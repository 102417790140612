// Importing the required libraries
import React, { useEffect } from 'react'; // for react
import ReactGA from "react-ga4";
import axios from "axios"; // for API calls
import {Text, Input, Button, Heading, Card, Tabs, TabPanel, TabPanels, CardBody, Stack, Box, StackDivider, TabList, Tab,
    SkeletonText, InputGroup, InputRightElement, Grid, GridItem} from '@chakra-ui/react'
import { useParams, useNavigate } from 'react-router-dom';

// send page view to Google Analytics
ReactGA.send({ hitType: "pageview", page: "/pdfviewer", title: "PDF Page" });

/** 
   * Function to render the paper viewer
  */
export default function PDFViewer({elementLink, setElementLink, handleLinkFormSubmit, selectedElementDetails, 
  handleNewChatInput, chatHistory, elementLoading, chatHumanInput, setChatHumanInput,
  setSelectedElementDetails, openAIKey, toggleElementLoading, SERVER_URL}) {
    // get the link from the URL
    let {link} = useParams(); 
    // state to save the social media post
    const [socialMediaPost, setSocialMediaPost] = React.useState("");
    // state to capture if the social media post is loading
    const [socialMediaPostLoading, setSocialMediaPostLoading] = React.useState(false);

    // react router navigate
    let navigate = useNavigate();

    // Function to fetch the details or process the paper from the backend API
    // If the link is provided, replace the elementLink with the link from the URL
    if (link) {
      // check if the link is valid
      const isValidLink = link.includes("pdf");
      // check if it is a valid url
      const isValidURL = link.startsWith("http") || link.startsWith("https");
      // if link in not valid
      if (!isValidURL || !isValidLink) {
        // 
        alert("Invalid URL, plz use with valid PDF link."); // Alert the user to enter a valid PDF link
        // setElementLink("");
        // TODO: show 404 page
        navigate("/");
      }        
      else {
        // set the element link
        // replace http with https 
        // console.log("setting before link: ", link);
        if (link.startsWith("http://")) {
          link = link.replace("http://", "https://");
        }
        // if the .pdf is present in the end, remove it
        // this is to avoid the redirection to http PDF url link
        // console.log("link before pdf removal: ", link);
        if (link.endsWith(".pdf")) {
          link = link.replace(".pdf", "");
        }
        // console.log("link after pdf removal: ", link);
        setElementLink(link);
        // console.log("setting link: ", link);
        // console.log("elementLink: ", elementLink);
      }
    }

    // effect hook to fetch the element details from the backend API
    useEffect(() => {
      // if element link is not empty
      // console.log("pdf viewer,elemnt link: ", elementLink)
      if (elementLink !== "") {
        fetchElementDetails(elementLink, setSelectedElementDetails, openAIKey, toggleElementLoading, SERVER_URL); // fetch summary from backend API
      }
    }, [elementLink, openAIKey]);

    // return the pdf viewer
    return (
        <div className="paper-container">
            <div className="pdf-viewer">
              <iframe
                src={elementLink}
                title="Arxiv Paper PDF"
                className="pdf-frame"
              />
            </div>
            <div className="pdf-viewer-right-panel-container">
              <Tabs style={{height: "100%"}}>
                <TabList style={{height: "6%"}}>
                  <Tab><Heading as='h6' size='md'>Summary 📚</Heading></Tab>
                  <Tab><Heading as='h6' size='md'>Ask Paper ✍️</Heading></Tab>
                  <Tab><Heading as='h6' size='md'>Share 🚀</Heading></Tab>
                </TabList>
                <TabPanels style={{height: "94%"}}>
                  <TabPanel> {/* Summary Panel */}
                    <Card>
                    <CardBody>
                    <SkeletonText isLoaded={!elementLoading} mt='4' noOfLines={4} spacing='4' skeletonHeight='2' >
                      <Stack divider={<StackDivider />} spacing='4'>
                          {selectedElementDetails.content.summary.map((item, index) => (
                            <QuestionAnswerViewer key={index} question={(index+1) + ". " + item[0]} answer={item[1]} />
                        ))}
                      </Stack>
                    </SkeletonText>
                    </CardBody>
                    </Card>
                  </TabPanel>
                  <TabPanel className='chat-container'> {/* Ask Paper Panel */}
                  {elementLoading ? 
                  <SkeletonText isLoaded={!elementLoading} mt='4' noOfLines={4} spacing='4' />:
                    <div className='chat-container'>
                      <div className="chat-history"> {/* Chat history panel */}
                        {chatHistory.map((item, index) => (
                          <ChatHistoryViewer key={index} text={item.text} type={item.type} />
                        ))}
                      </div>
                      <div className="chat-form"> {/*  Chat Input Panel */}
                        <InputGroup size='md'>
                            <Input
                                pr='4.5rem'
                                type="text"
                                id="elementLink"
                                value={chatHumanInput}
                                onChange={e => setChatHumanInput(e.target.value)}
                                className="input"
                                placeholder="ChatGPT answer this about the paper!"
                                required
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' type="submit" colorScheme='blue' onClick={handleNewChatInput}>
                                    Ask!
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                      </div>
                    </div>
                    }
                  </TabPanel>
                  <TabPanel> {/* Share Panel */}
                  {elementLoading ? 
                  <SkeletonText isLoaded={!elementLoading} mt='4' noOfLines={4} spacing='4' />:
                    <Card>
                    <CardBody>
                      <Grid templateColumns='repeat(1, 1fr)' gap={3}>
                        {/* <GridItem >
                          <Button colorScheme='blue' onClick={() => {}}>
                            Copy Link 🔗
                          </Button>
                        </GridItem> */}
                        <GridItem >
                          <Button onClick={() => {generateLinkedInPost(selectedElementDetails, openAIKey, setSocialMediaPost, setSocialMediaPostLoading, SERVER_URL, link)}}>
                            Generate LinkedIn Post 🤖
                          </Button>
                        </GridItem>
                      </Grid>
                      <br />
                      {socialMediaPostLoading ? 
                        <SkeletonText isLoaded={!socialMediaPostLoading} mt='4' noOfLines={4} spacing='4' />:
                        <Text fontSize='ms' className='social-media-post-text-view'>{socialMediaPost}</Text>
                      }
                    </CardBody>
                    </Card>
                    }
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
        </div>
    );
  };

// Function to render the question and answer
function QuestionAnswerViewer({ question, answer }) {
    return (
        <Box>
            <Heading as='h6' size='sm'>{question}</Heading>
            {/* <br /> */}
            <Text fontSize="sm">{answer}</Text>
            {/* <Divider /> */}
        </Box>
    );
}

// Function to render the chat history, for type 0 its human chat (show as right aligned) 
// and for type 1 its bot chat (show as left aligned).
function ChatHistoryViewer({ text, type }) {
    return (
        <Box className={`chat-bubble ${type === 0 ? 'human-chat-bubble' : 'machine-chat-bubble'}`}>
        <Text fontSize="sm">{text}</Text>
        </Box>
    );
}

// Function to generate linkedin post
function generateLinkedInPost(selectedElementDetails, openAIKey, setSocialMediaPost, setSocialMediaPostLoading, SERVER_URL, link) {
  setSocialMediaPostLoading(true);
  // console.log("selectedElementDetails namespace", selectedElementDetails.namespace)
  axios
    .post(SERVER_URL + `/generate_linkedin_post`, {openai_api_key: openAIKey, element_namespace: selectedElementDetails.namespace})
    .then((res) => {
      if (res.data.status === 200) {
        const sankshepLink = "https://sankshep.co.in/PDFViewer/" + encodeURIComponent(link);
        const post = res.data.result.content + "\n\n🔗 Paper link (powered by Sankshep 👋 ): " + sankshepLink;
        setSocialMediaPost(post);
        setSocialMediaPostLoading(false);
      }
      else if (res.data.status === 401){
        setSocialMediaPostLoading(false);
        alert("Please enter valid OpenAI API Key to proceed!");
      }
      else {
        setSocialMediaPostLoading(false);
        alert("Something went wrong!");
      }
    })
}

// API Endpoints calling functions
// Function to fetch the summary
function fetchElementDetails(elementLink, setSelectedElementDetails, openAIKey, toggleElementLoading, SERVER_URL) {
  setSelectedElementDetails({"title": "", 'namespace': "",'content': {'summary': []}}); // Reset the selectedElementDetails
  toggleElementLoading(true); // set element loading to true
  // console.log("fetching element details");
  axios
    .post(SERVER_URL + `/run_sankshep_for_link`, {link: elementLink, openai_api_key: openAIKey})
    .then((res) => {
      if (res.data.status === 200) {
        setSelectedElementDetails(res.data.result);
        toggleElementLoading(false);
        // console.log("200 status", res.data.result);
      }
      else if (res.data.status === 401){
        // console.log('ALERT add openAI key!', res);
        alert("Please enter valid OpenAI API Key to proceed!")
        setSelectedElementDetails({"title": "", 'namespace': "",'content': {'summary': [['Missing OpenAI Key!', '', 0.1]]}}); // Reset the selectedElementDetails
        toggleElementLoading(false);
      }
      else {
        // console.log('Something went wrong!', res)
        alert("Something went wrong!")
        setSelectedElementDetails({"title": "", 'namespace': "",'content': {'summary': [['Something went wrong!', 'Please try again after some time. If the issue persist, contact us!', 0.1]]}}); // Reset the selectedElementDetails
        toggleElementLoading(false);
      }
    })
    .catch(error => {
      console.error("Error", error);
      setSelectedElementDetails({"title": "", 'namespace': "",'content': {'summary': [['Something went wrong!', 'Please try again after some time. If the issue persist, contact us!', 0.1]]}}); // Reset the selectedElementDetails
      toggleElementLoading(false);
    });
};
