// Importing the required libraries
import React, { useState, useEffect } from 'react'; // for react
import axios from "axios"; // for API calls
import { TypeAnimation } from 'react-type-animation'; // for typing animation
import { Text, Input, InputGroup, InputRightElement, Button, Tag,
    Card, Heading, Highlight, Grid, GridItem, CardHeader, CardBody, CardFooter} from '@chakra-ui/react'
import MadeWithLove from 'react-made-with-love'; // for footer
import { Link } from "react-router-dom";


/**
 * Function to render the paper link input form
 */
export default function Home({elementLink, setElementLink, handleLinkChange, handleLinkFormSubmit, SERVER_URL}) {

    // state to hold the get the recent elements from the DB
    const [recentPDFElements, setRecentPDFElements] = useState([{'title': '', 'link': '', 'summary': '', 'categories': []}]); 

    // effect hook to fetch the element details from the backend API
    useEffect(() => {
        // setElementLink to blank
        setElementLink("");
        // get the recent pdfs
        axios
        .post(SERVER_URL + `/get_recent_pdfs`, {count: 5})
        .then((res) => {
            if (res.data.status === 200) {
                setRecentPDFElements(res.data.result)
            }
            else if (res.data.status === 500) {
                console.log("Error in get_recent_pdfs ")
            }
        })
      }, []);

    return (
        <div className="App">
            {/* The title of the page */}
            <div className='home-body'>
                <div className='home-body-search'>
                    <div className='home-body-search-title'>
                        <TypeAnimation
                            sequence={[
                                // Same String at the start will only be typed once, initially
                                'संक्षेप',1000,
                                'Sankshep',1000,
                                // "概要", 1000,
                                // "abstrakt", 1000,
                                // "abstraite", 1000,
                            ]}
                            speed={50}
                            style={{ fontSize: '3em' }}
                            repeat={Infinity}
                        />
                    </div>
                    <br />
                    {/* The subtitle */}
                    <div className='home-body-search-subtitle'>
                        <Text fontSize='2xl'>
                            <Highlight query={['summary', 'questions']} styles={{ px: '2', py: '1', rounded: 'full', bg: 'pink.100' }}>
                                Enter Arxiv link to view its summary and ask questions! 🔥
                            </Highlight>
                        </Text>
                    </div>
                    <br /><br />
                    {/* Div containing input and a dropdown option */}
                    <div className="home-body-search-inputcontainer">
                        {/* Input for the paper link */}
                        <InputGroup size='md'>
                            <Input
                                pr='4.5rem'
                                type="text"
                                id="elementLink"
                                value={elementLink}
                                onChange={handleLinkChange}
                                className="input"
                                placeholder="https://arxiv.org/pdf/1706.03762.pdf"
                                required
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' type="submit" colorScheme='blue' onClick={handleLinkFormSubmit}>
                                    Go!
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </div>
                </div>
                <div className='home-body-recent'>
                    <div className='home-body-recent-title'>
                        <Text fontSize='xl'>
                            <Highlight query={['Recent papers']} styles={{ px: '2', py: '1', rounded: 'full', bg: 'pink.100' }}>
                                Or check-out some Recent papers 👉
                            </Highlight>
                        </Text>
                    </div>
                    <Grid className="home-body-recent-body" templateColumns='repeat(5, 1fr)' gap={6}>
                        {recentPDFElements.map((pdfelement) => (
                            <GridItem className="home-body-recent-grid" >
                                <Link to={ '/PDFViewer/' + encodeURIComponent(pdfelement.link)}>
                                    <Card className='home-body-recent-grid-card'>
                                        <CardHeader>
                                            <Heading size='sm'>{pdfelement.title}</Heading>
                                        </CardHeader>
                                        <CardFooter className='home-body-recent-grid-card-footer'>
                                            {pdfelement.categories.map((category)=>(
                                                <Tag>{category}</Tag>
                                            ))}
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                        ))}
                    </Grid>
                </div>
                
            </div>
            <div className='home-footer'> 
                <MadeWithLove 
                by="Mohit Mayank"
                link="https://mohitmayank.com"
                emoji
                />
            </div>
        </div>
    );
  };