// Desc: Navigation bar for the website

// import React, { useState } from 'react';

// import elements from Chakra UI
import {
    Box,
    Flex,
    Text,
    Heading,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    PopoverFooter,
    Input
} from '@chakra-ui/react';

// import elements from chakra-icons
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons';

import { Link as RLink } from "react-router-dom";

// 
export default function WithSubnavigation({openAIKey, onOpenAIKeyChange}) {

    // useDisclosure hook
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Box>
        <Flex
            bg={useColorModeValue('white', 'gray.800')}
            color={useColorModeValue('gray.600', 'white')}
            // minH={'10px'}
            py={{ base: 2 }}
            px={{ base: 4 }}
            borderBottom={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.900')}
            align={'center'}>
            <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}>
            <IconButton
                onClick={onToggle}
                icon={
                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                }
                variant={'ghost'}
                aria-label={'Toggle Navigation'}
            />
            </Flex>
            <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <RLink to = '/'>
                <Heading
                    as='h1' size="md"
                    textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                    >
                    Sankshep
                </Heading>
            </RLink>

            <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                <DesktopNav />
            </Flex>
            </Flex>

            <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            // spacing={6}
            >
            {/* <Button
                as={'a'}
                fontSize={'sm'}
                fontWeight={400}
                variant={'link'}
                href={'#'}>
                Sign In
            </Button> */}
            <Popover>
                <PopoverTrigger>
                    <Button
                        as={'a'}
                        display={{ base: 'none', md: 'inline-flex' }}
                        fontSize={'sm'}
                        fontWeight={600}
                        color={'white'}
                        bg={'pink.400'}
                        href={'#'}
                        _hover={{
                        bg: 'pink.300',
                        }}>
                        OpenAI Key
                    </Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>
                        <Text fontSize='md'><b>Enter your OpenAI Key:</b></Text>
                    </PopoverHeader>
                    <PopoverBody>
                        <Input 
                            placeholder="sk-bauWsxscn....." 
                            value={openAIKey}
                            onChange={onOpenAIKeyChange}
                        />
                    </PopoverBody>
                    <PopoverFooter>
                        <Text fontSize='sm'>Rest easy, we do not store your key 🙈</Text>
                        <Text fontSize='sm'>Refer <a href='https://www.youtube.com/watch?v=swcXTgvjHSA' style={{textDecoration: "underline"}}>this video guide</a> on how to create your own API key!</Text>
                        
                    </PopoverFooter>

                </PopoverContent>
            </Popover>
            </Stack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
            <MobileNav />
        </Collapse>
        </Box>
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    return (
        <Stack direction={'row'} spacing={4}>
        {NAV_ITEMS.map((navItem) => (
            <Box key={navItem.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
                <PopoverTrigger>
                <Link
                    p={2}
                    // href={navItem.href ?? '#'}
                    fontSize={'sm'}
                    fontWeight={500}
                    color={linkColor}
                    _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                    }}>
                    <RLink to={navItem.href}>
                        {navItem.label}
                    </RLink>
                </Link>
                </PopoverTrigger>

                {navItem.children && (
                <PopoverContent
                    border={0}
                    boxShadow={'xl'}
                    bg={popoverContentBgColor}
                    p={4}
                    rounded={'xl'}
                    minW={'sm'}>
                    <Stack>
                    {navItem.children.map((child) => (
                        <DesktopSubNav key={child.label} {...child} />
                    ))}
                    </Stack>
                </PopoverContent>
                )}
            </Popover>
            </Box>
        ))}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
        <Link
        href={href}
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
        <Stack direction={'row'} align={'center'}>
            <Box>
            <Text
                transition={'all .3s ease'}
                _groupHover={{ color: 'pink.400' }}
                fontWeight={500}>
                {label}
            </Text>
            <Text fontSize={'sm'}>{subLabel}</Text>
            </Box>
            <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
            </Flex>
        </Stack>
        </Link>
    );
};

const MobileNav = () => {
    return (
        <Stack
        bg={useColorModeValue('white', 'gray.800')}
        p={4}
        display={{ md: 'none' }}>
        {NAV_ITEMS.map((navItem) => (
            <MobileNavItem key={navItem.label} {...navItem} />
        ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
        <Flex
            py={2}
            as={Link}
            href={href ?? '#'}
            justify={'space-between'}
            align={'center'}
            _hover={{
            textDecoration: 'none',
            }}>
            <Text
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}>
            {label}
            </Text>
            {children && (
            <Icon
                as={ChevronDownIcon}
                transition={'all .25s ease-in-out'}
                transform={isOpen ? 'rotate(180deg)' : ''}
                w={6}
                h={6}
            />
            )}
        </Flex>

        <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
            <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}>
            {children &&
                children.map((child) => (
                <Link key={child.label} py={2} href={child.href}>
                    {child.label}
                </Link>
                ))}
            </Stack>
        </Collapse>
        </Stack>
    );
};

interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
     {label: 'Home', href: '/'},
     {label: 'GPTs Hub', href: '/gptshub'},
    //  {label: 'About', href: '/About'},
     {label: 'FAQ', href: '/FAQ'},
];
// const NAV_ITEMS: Array<NavItem> = [
    // {
    //     label: 'Inspiration',
    //     children: [
    //     {
    //         label: 'Explore Design Work',
    //         subLabel: 'Trending Design to inspire you',
    //         href: '#',
    //     },
    //     {
    //         label: 'New & Noteworthy',
    //         subLabel: 'Up-and-coming Designers',
    //         href: '#',
    //     },
    //     ],
    // },
    // {
    //     label: 'Find Work',
    //     children: [
    //     {
    //         label: 'Job Board',
    //         subLabel: 'Find your dream design job',
    //         href: '#',
    //     },
    //     {
    //         label: 'Freelance Projects',
    //         subLabel: 'An exclusive list for contract work',
    //         href: '#',
    //     },
    //     ],
    // },
    // {
    //     label: 'Learn Design',
    //     href: '#',
    // },
    // {
    //     label: 'Hire Designers',
    //     href: '#',
    // },
// ];