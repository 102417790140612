// import packages
import React, { useState } from 'react';
import axios from "axios"; // for API calls
import ReactGA from "react-ga4";
import WithSubnavigation from './components/nav';
import { ChakraProvider, extendTheme} from '@chakra-ui/react'
import {Routes, Route, useNavigate} from "react-router-dom"; // for routing

// import components
import './App.css';
import Home from './components/home';
import PDFViewer from './components/pdfviewer';
import GPTs from './components/gpts';
import FAQ from './components/faq';

// init the google analytics
ReactGA.initialize("G-HHY384MM5R");
ReactGA.send({ hitType: "pageview", page: "/home", title: "Home Page" });

// Const 
const SERVER_URL = "https://sankshep-backend.onrender.com";
// const SERVER_URL = "http://0.0.0.0:10000";

// Function to fetch the question answer
function performQA(question, element_namespace, chatHistory, setChatHistory, openAIKey) {
  // 
  axios
    .post(SERVER_URL + `/perform_qa`, {question: question, element_namespace: element_namespace, openai_api_key: openAIKey})
    .then((res) => {
      // console.log(chatHistory);
      // if status is 200 - add the question and answer to the chat history
      if (res.data.status === 200) {
        setChatHistory([...chatHistory, {"text": question, "type": 0}, {"text": res.data.answer, 'type': 1}]);
      }
      else if (res.data.status === 401){
        setChatHistory([...chatHistory, {"text": question, "type": 0}, {"text": "Please enter valid OpenAI API Key!", 'type': 1}]);
      }
      else {
        setChatHistory([...chatHistory, {"text": question, "type": 0}, {"text": "Something went wrong, please check back after some time!", 'type': 1}]);
      }
    })
    .catch(error => {
      console.error("Error", error);
      setChatHistory([...chatHistory, {"text": question, "type": 0}, {"text": "Something went wrong, please check back after some time!", 'type': 1}]);
    });
};

/**
 * Chakra UI theme
 */
const theme = extendTheme({
  colors: {
    brand: {
      100: "#f7fafc",
      // ...
      900: "#1a202c",
    },
  },
})

/**
 * Main App function
 */
const App = () => {
  // Boolean to check if the element is loading
  const [elementLoading, toggleElementLoading] = useState(true); 
  // OpenAI API Key
  const [openAIKey, setOpenAIKey] = useState(''); 
  // Save the paper link in the state
  const [elementLink, setElementLink] = useState('');
  // Save the summary in the state
  const [selectedElementDetails, setSelectedElementDetails] = useState({"title": "", 'namespace': "",'content': {'summary': []}});
  // Save the current question in the state
  const [chatHumanInput, setChatHumanInput] = useState('');
  // Save the history of chat messages
  const [chatHistory, setChatHistory] = useState([]);
  // react router navigate
  let navigate = useNavigate();

  // Function to handle the change in OpenAI API Key
  function handleOpenAIKeyChange(e) {
    setOpenAIKey(e.target.value); // Set openAIKey to the value of the input
  };
  
  // Function to handle the link submit
  const handleLinkFormSubmit = (e) => {
    e.preventDefault(); // Prevents page refresh
    // check if this is a valid link or not -- for now only PDF
    const isValidLink = elementLink.includes("pdf");
    // check if it is a valid url
    const isValidURL = elementLink.startsWith("http://") || elementLink.startsWith("https://");
    // if valid link
    if (isValidLink && isValidURL) {
      // navigate to the pdf viewer page
      navigate("/PDFViewer/" + encodeURIComponent(elementLink));
    } else {
      alert("Please enter a valid PDF url link!"); // Alert the user to enter a valid PDF link
    }
  }

  // Function to handle the change in paper link
  const handleLinkChange = (e) => {
    setElementLink(e.target.value); // Set elementLink to the value of the input
  };

  // Function to handle submittion of new chat input
  const handleNewChatInput = (e) => {
    e.preventDefault(); // Prevents page refresh
    setChatHistory([...chatHistory, {"text": chatHumanInput, "type": 0}]); // Add the human input to the chat history
    performQA(chatHumanInput, selectedElementDetails.namespace, chatHistory, setChatHistory, openAIKey); // Perform QA on the human input
    setChatHumanInput(""); // Reset the input value
  };

  return (
    <ChakraProvider theme={theme} style={{width: "100%", height: "100%"}}>
      <WithSubnavigation openAIKey={openAIKey} onOpenAIKeyChange={handleOpenAIKeyChange}/> 
      <Routes>
        <Route path="/" element={<Home elementLink={elementLink} setElementLink={setElementLink} handleLinkChange={handleLinkChange} handleLinkFormSubmit={handleLinkFormSubmit} SERVER_URL={SERVER_URL}/>} />
        <Route path="/pdfViewer/:link?" element={<PDFViewer elementLink={elementLink} setElementLink={setElementLink} handleLinkFormSubmit={handleLinkFormSubmit}
              selectedElementDetails={selectedElementDetails} handleNewChatInput={handleNewChatInput} chatHistory={chatHistory} 
              elementLoading={elementLoading} chatHumanInput={chatHumanInput} setChatHumanInput={setChatHumanInput}
              setSelectedElementDetails={setSelectedElementDetails} openAIKey={openAIKey} toggleElementLoading={toggleElementLoading}
              SERVER_URL={SERVER_URL}/>} />
        <Route path="/gptshub" element={<GPTs SERVER_URL={SERVER_URL}/>}></Route>
        <Route path="/faq" element={<FAQ />}></Route>
      </Routes>
    </ChakraProvider>
  );
};

export default App;