// Importing the required libraries
import React from 'react'; // for react
import ReactGA from "react-ga4";
import { Text, Card, CardBody, Stack, StackDivider, Box, Heading } from '@chakra-ui/react'

// send page view to Google Analytics
ReactGA.send({ hitType: "pageview", page: "/faq", title: "FAQ Page" });

/**
 * Function to render the paper link input form
 */
export default function FAQ() {
    return (
        <div class='faq-container'>
            <Card>
            <CardBody class="faq-container-card-body">
                <Stack divider={<StackDivider />} spacing='4'>
                    {faqList.map((item, index) => (
                    <FAQViewer index={index} question={item.question} answer={item.answer} />
                ))}
                </Stack>
            </CardBody>
            </Card>
        </div>
    )           
};

// Function to render the question and answer
function FAQViewer({ index, question, answer }) {
    return (
        <Box>
            <Heading as='h6' size='md'>{index + 1 + ". " + question}</Heading>
            <br />
            <Text fontSize="md">{answer}</Text>
            <br />
        </Box>
    );
}

// Constants to store the FAQ questions
const faqList = [
    {
        "question": "What is Sankshep?",
        "answer": "Sankshep is your ChatGPT powered AI assistant that can provides summaries and a way to chat with arXiv papers (more sources coming soon!). Want to use it? Just enter the arXiv paper's PDF URL and hit enter!"
    },
    {
        "question": "Is Sankshep completely free to use?",
        "answer": "Yes and No. Here is the deal - While Sankshep doesn't charge for its services, you do pay for OpenAI's credits consumption for summary and QA. To be clear, you will be charged for the `text-embedding-ada-002` ($0.0004 per ~750 words) usage and `ChatGPT` usage (it is $0.002 per ~750 words). On average we have seen costing of less than 1 US cent (<$0.01) for a 10 page PDF."
    },
    {
        "question": "How long does it take to summarize a paper?",
        "answer": "The time taken to summarize a paper depends on the length of the paper. On average, it takes about 20 to 30 seconds to summarize a 10 page paper."
    },
    {
        "question": "'Ask Paper' is not able to answer my questions. What should I do?",
        "answer": "Sankshep uses OpenAI's ChatGPT model to answer questions about the paper. While the model is trained on a large corpus of data, it may not be able to answer all questions. If you are not satisfied with the answer, you can try rephrasing the question or asking a different question."
    },
    {
        "question": "How does Sankshep work?",
        "answer": "Sankshep uses advanced natural language processing algorithms and machine learning models to automatically generate summaries of arXiv papers. To be exact, it leverages OpenAI's embedding and LLM services (ChatGPT) to assist users in asking questions about the paper."
    },
    {
        "question": "What are GPTs?",
        "answer": "GPT, originally is a series of NLP models that can generate text based on a prompt. For our context, OpenAI recently released GPTs, a new way to create version of their latest ChatGPT/GPT4 models. While the community is actively creating and sharing their custom GPTs, there is a lack of repository of GPTs. GPTs Hub is an answer to that!"
    },
    {
        "question": "What is arXiv?",
        "answer": "ArXiv is a repository of scientific papers in various disciplines, including mathematics, physics, computer science, and more."
    },
    {
        "question": "How accurate are the summaries provided by Sankshep?",
        "answer": "While Sankshep strives to generate accurate summaries, it's important to note that the quality may vary. The summaries are based on machine learning models, and their accuracy depends on various factors, such as the complexity of the paper and the language model's training data."
    },
    {
        "question": "Are there any limitations to the length of the papers Sankshep can summarize?",
        "answer": "Sankshep can summarize papers of varying lengths. However, due to the constraints of summarization algorithms, extremely lengthy papers might have more condensed summaries, potentially missing some intricate details. It's best suited for papers of moderate length. Also note, longer PDFs will consumer more credits and will take longer time to process."
    },
    {
        "question": "I have some feedback for Sankshep. How can I share it?",
        "answer": "We would love to hear your feedback! Please feel free to reach out to me at 👉 mohitmayank1@gmail.com 👈. We will get back to you as soon as possible."
    }
    
];