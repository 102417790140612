// Importing the required libraries
import React, { useState, useEffect } from 'react'; // for react
// import React from 'react'; // for react
import ReactGA from "react-ga4";

import axios from "axios"; // for API calls

import openailogo from '../assets/openai_img.png'; // to show icon

import { Text, Heading, Highlight } from '@chakra-ui/react'

// send page view to Google Analytics
ReactGA.send({ hitType: "pageview", page: "/gptshub", title: "GPTsHub" });

/**
 * Function to render the paper link input form
 */
export default function GPTs({SERVER_URL}) {

    // state to hold the get the recent elements from the DB
    const [gptsRecommendations, setgptsRecommendations] = useState([{'title': '', 'description': ''}]);

    // effect hook to fetch the element details from the backend API
    useEffect(() => {
        axios
        .post(SERVER_URL + `/get_recommended_gpts`, {count: 300})
        .then((res) => {
            if (res.data.status === 200) {
                setgptsRecommendations(res.data.result)
                // console.log("GPTS result ---> ", res.data.result)
            }
            else if (res.data.status === 500) {
                console.log("Error in get_recommended_gpts ")
            }
        })

    }, []);
    

    return (
        <div className="gpts-body">
        <div className='gpts-header'>
            <Heading>GPTs Hub</Heading>
        </div>
        <div className='gpts-highlights'>
            <Text fontSize='2xl'>
                <Highlight query={['community', 'building']} styles={{ px: '2', py: '1', rounded: 'full', bg: 'pink.100' }}>
                    {"👷 Here is what the community is building! 🔨"} 
                </Highlight>
            </Text>
        </div>
        <div class="gpts-card-container">
            {gptsRecommendations.map((item, index) => (
                <div class="gpts-card">
                    <a href={item.link} target="_blank">
                        <img src={openailogo} alt={item.title}/>
                        <div class="gpts-card-content">
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                    </a>
                </div>
            ))}
        </div>
        </div>
    )           
};